<template>
  <div id="div-app-rc-kiosk" data-testid="container-rc-kiosk">
    <v-app id="app-rc-kiosk">
      <!--{{websocket_common_conn}}-->
      <!--{{websocket_common_conn.readyState}}-->
      <!--{{websocket_common_conn.OPEN}}-->

      <template v-if="!haveGeneralUserRightsViewPage">
        <div data-testid="rc-notification-no-rights">
          You don't have rights for RC for this engine...
        </div>
      </template>

      <template v-else>
        <template v-if="loading">
          <!--<div>loading data...</div>-->
          <div></div>
        </template>

        <template v-else>
          <!--          <template-->
          <!--                  v-if="!haveUserSpecificRights || !rcEngineData.is_active || websocket_common_conn==null || websocketReadyStatus != websocket_common_conn.OPEN || !rcEngineData._engine.is_online">-->

          <template v-if="isMaintanceMode">
            <!--|| websocket_common_conn.readyState != websocket_common_conn.OPEN-->
            <v-card
              color="amber darken-4"
              dark
              height="100%"
              class="d-flex flex-column"
              data-testid="notification-system-maintenance"
            >
              <v-card-text class="flex">
                <v-container fill-height>
                  <v-layout align-center>
                    <v-flex text-xs-center>
                      <h4 class="display-3 text-center">System maintenance.</h4>
                      <p class="title text-center">Please come back later.</p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
            <!-- <v-jumbotron color="amber darken-4" dark height="100%">
              <v-container fill-height>
                <v-layout align-center>
                  <v-flex text-xs-center>
                    <h4 class="display-3">
                      System maintenance.
                    </h4>
                    <p class="title">
                      Please come back later.
                    </p>

                  </v-flex>
                </v-layout>
              </v-container>
            </v-jumbotron> -->
          </template>
          <!--<h3 class="display-3">RC engine Offline...</h3>-->
          <!--<span style="position:absolute; bottom: 2px; left: 3px;">-->
          <!--<v-btn text icon color="green"  >-->
          <!--<v-icon>cached</v-icon>-->
          <!--</v-btn>-->
          <!--</span>-->

          <template v-else>
            <template v-if="activeGroup == null && canChangePlayedItem">
              <div class="yellow darken-2" style="min-height: 120px">
                <v-layout column fill-height>
                  <v-flex xs12 sm12 text-xs-center>
                    <div
                      class="black--text text-center font-weight-bold headline pt-6 pb-4"
                    >
                      New Jersey Hall of Fame
                    </div>
                    <div class="rc-njhof-logo"></div>
                  </v-flex>
                  <v-flex xs12 sm12 class="pb-2">
                    <div
                      class="black--text text-md-left title pl-3 rc-text-select-hologram"
                    >
                      <!--                      <v-icon class="black&#45;&#45;text" style="visibility: hidden;">keyboard_arrow_left</v-icon>-->
                      Select a hologram
                    </div>
                  </v-flex>
                </v-layout>
              </div>

              <v-layout row wrap class="pt-5 grey lighten-3">
                <v-flex xs10 sm10 md10 offset-sm1 offset-md1 text-xs-left>
                  <div
                    v-for="(groupItem, key) in getGroupsPlaylists()"
                    :key="'group-' + key"
                    class="mb-4"
                  >
                    <v-btn
                      block
                      large
                      color="white"
                      class="elevation-8 rc-group-btn black--text"
                      @click="goToGroup(key)"
                      v-ripple="{ class: `text--darken-2 yellow--text` }"
                      :data-testid="'btn-go-group-' + key"
                    >
                      {{ groupItem.group }}
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </template>

            <template v-if="activeGroup != null && canChangePlayedItem">
              <div
                class="yellow darken-2"
                style="min-height: 120px"
                data-testid="container-header"
              >
                <v-layout column fill-height>
                  <v-flex xs12 sm12 text-xs-center>
                    <v-flex xs12 sm12 text-xs-center>
                      <v-toolbar-title
                        class="black--text text-center font-weight-bold headline pt-6 pb-4"
                      >
                        New Jersey Hall of Fame
                        <div class="rc-njhof-logo"></div>
                      </v-toolbar-title>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm12 class="pb-2">
                    <a
                      @click="backToGroups()"
                      class="black--text text-md-left title rc-text-select-hologram"
                      data-testid="link-select-hologram"
                    >
                      <v-icon class="black--text" style="margin-bottom: 3px"
                        >keyboard_arrow_left</v-icon
                      >
                      Select a hologram
                    </a>
                  </v-flex>
                </v-layout>
              </div>

              <v-layout
                row
                wrap
                class="pt-5 grey lighten-3"
                data-testid="container-choose-question"
              >
                <v-flex
                  xs10
                  sm10
                  md10
                  offset-sm1
                  offset-md1
                  text-xs-left
                  text-md-left
                >
                  <v-toolbar-title
                    class="black--text text-xs-center text-center font-weight-black headline pb-5"
                  >
                    {{ getActiveGroupInfo().group }}
                  </v-toolbar-title>

                  <v-toolbar-title
                    class="black--text text-xs-center text-center title mt-5 mb-5"
                    data-testid="title-choose-question"
                  >
                    Chose a Question
                  </v-toolbar-title>

                  <div
                    v-for="item in getActiveGroupInfo().items"
                    :key="item.key"
                    class="mb-3"
                  >
                    <v-btn
                      block
                      large
                      color="white"
                      class="elevation-8 rc-file-btn black--text text-wrap"
                      @click="playSpecificItem(item)"
                      v-ripple="{ class: `text--darken-2 yellow--text` }"
                      :loading="item.loading"
                      data-testid="btn-play-item"
                    >
                      {{ item.name }}
                      <template v-slot:loader><span>Loading...</span></template>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </template>

            <template v-if="!canChangePlayedItem">
              <!-- <v-jumbotron :color="getNowPlayingFileName() == null ? 'amber darken-4' : 'yellow darken-2'" dark
                           height="100%">
                <v-container fill-height>
                  <v-layout align-center>
                    <v-flex text-xs-center>
                      <div v-if="getNowPlayingFileName() == null">
                        <h3 class="display-3">
                          Need init and start
                        </h3>
                      </div>
                      <div v-else>
                        <h4 class="display-3">
                          {{ getNowPlayingFileName() }}
                        </h4>
                        <p class="title">
                          Please wait
                        </p>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-jumbotron> -->
              <v-card
                :color="
                  getNowPlayingFileName() == null
                    ? 'amber darken-4'
                    : 'yellow darken-2'
                "
                dark
                height="100%"
                class="d-flex flex-column"
                data-testid="container-now-playing"
              >
                <v-card-text class="flex">
                  <v-container fill-height>
                    <v-layout align-center>
                      <v-flex text-xs-center>
                        <div v-if="getNowPlayingFileName() == null">
                          <h3
                            class="display-3 text-center"
                            data-testid="notification-need-init-start"
                          >
                            Need init and start
                          </h3>
                        </div>
                        <div v-else>
                          <h4
                            class="display-3 text-center"
                            data-testid="title-now-playing-item"
                          >
                            {{ getNowPlayingFileName() }}
                          </h4>
                          <p class="title text-center">Please wait</p>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
          </template>
        </template>
      </template>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import WsConnMixin from "@/mixins/websocket-connection.js"
import RCPlaylistsMixin from "@/mixins/remote-control/rc-playlists"
import _ from "lodash"
import GraylogService from "@/common/graylog.service"
import publicIp from "public-ip"

export default {
  mixins: [WsConnMixin, RCPlaylistsMixin],
  data() {
    return {
      activeGroup: null,
      playlistActiveIndex: 0, // default active playlist index
      canChangePlayedItem: true,
      loading: true,
      playingStarted: false,
      haveGeneralUserRightsViewPage: true,
      haveUserSpecificRights: true,
      socketInitialized: false,
      logger: null,
      productionMode: false, // if true do logging
      kioskName: "undefined",
      objRedirectGroups: null,
      websocketReadyStatus: 1, // need refactor check websocket connection status without this param
      playlist: null,
      rcHashInfo: null,
      dataInitialized: false,
    }
  },
  created: function () {
    let self = this
    self.$store
      .dispatch("rcPlaylistsKioskHashInfoAction", self.$route.params.link_hash)
      .then(function (result) {
        self.rcHashInfo = result.data.data
        self.productionMode =
          !_.isUndefined(self.$route.query.production) &&
          self.$route.query.production == "true"
            ? true
            : false
        self.kioskName = !_.isUndefined(self.$route.query.kiosk_name)
          ? self.$route.query.kiosk_name
          : "undefined"
        self.initApp()
        self.pingHeartbit = setInterval(() => {
          self.ping()
        }, parseInt(process.env.VUE_APP_KIOSK_PING_INTERVAL))
        document.getElementsByTagName("meta")["viewport"].content =
          "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"

        setTimeout(() => {
          if (self.isMaintanceMode) {
            self.logGraylog("in_maintenance")
          } else {
            self.logGraylog("in_alive")
          }
        }, 1500)
      })
      .catch((err) => {
        self.$router.push({ path: "/login" }).catch((err) => {})
      })
    // window.addEventListener('offline', (event) => {
    //   clearInterval(self.pingHeartbit);
    //   self.isWebOffline = true;
    // });
    // window.addEventListener('online', (event) => {
    //   self.isWebOffline = false;
    //   self.pingHeartbit = setInterval(() => {
    //     self.ping()
    //   }, parseInt(process.env.VUE_APP_KIOSK_PING_INTERVAL));
    // });
  },
  watch: {
    rcEngineData: function (newData, oldData) {
      this.switchCanChangePlayedItem()
      this.initTimerRedirectToGroups()
      this.playlist = this.getFirstPlaylist()
    },
    isWebOffline: function (newVal, oldVal) {
      // in case when web kiosk wake up (i.e. when no internet) we must refresh RC data
      if (!newVal && oldVal) {
        this.initApp()
      }
    },
    isMaintanceMode: function (newVal, oldVal) {
      if (this.dataInitialized) {
        if (newVal && !oldVal) {
          this.logGraylog("switch_maintenance")
        } else if (!newVal && oldVal) {
          this.logGraylog("switch_alive")
        }
      }
    },
  },
  methods: {
    checkUserRights() {
      let data = {
        engine_name: this.$route.params.engine_name,
        project: this.$route.params.project,
      }
      return this.$store.dispatch("rcPlaylistsEngineUserRightsAction", data)
    },

    initApp() {
      let self = this
      self.getEngineParsedInfo().then(function () {
        self.loading = false
      })

      if (!self.socketInitialized) {
        self.initWebsocketConnection()
      }

      // self.checkUserRights().then(function(result){
      //      self.getEngineParsedInfo().then(function(){
      //             self.loading = false;
      //             // ping log
      //             if (self.productionMode) {
      //                 setTimeout(function(){
      //                     self.pingLog()
      //                 }, 2000);
      //             }
      //         });
      //
      //         if (!self.socketInitialized) {
      //             self.initWebsocketConnection();
      //         }
      //
      //     // self.haveGeneralUserRightsViewPage = result.data.data['have_general_rights'];
      //     // self.haveUserSpecificRights = result.data.data['have_user_specific_rights'];
      //     //  if (self.haveUserSpecificRights) {
      //     //     self.getEngineParsedInfo().then(function(){
      //     //         self.loading = false;
      //     //         // ping log
      //     //         if (self.productionMode) {
      //     //             setTimeout(function(){
      //     //                 self.pingLog()
      //     //             }, 2000);
      //     //         }
      //     //     });
      //     //
      //     //     if (!self.socketInitialized) {
      //     //         self.initWebsocketConnection();
      //     //     }
      //     // } else {
      //     //     self.loading = false;
      //     // }
      //     // else {
      //     //     alert('RC is started under other user and you do not have rights to view playlists. Please relogin on ASPX');
      //     // }
      // });
    },

    getActiveGroupInfo() {
      return this.activeGroup != null
        ? this.getFirstPlaylist().groupsData[this.activeGroup]
        : null
    },

    getEngineParsedInfo() {
      let self = this
      let rcKey = _.get(self.rcHashInfo, ["kiosk", "rc_engine_key"])
      if (rcKey) {
        let data = {
          slug: self.getRCkey,
        }
        return this.$store
          .dispatch("rcPlaylistsEngineParsedInfoAction", data)
          .then(function (result) {
            self.resetLoadingFiles()
          })
      } else {
        alert("cannot")
      }
    },

    initWebsocketConnection() {
      let self = this
      self.socketInitialized = true
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          if (_.get(data, "path", "") == "1.0.ev.refresh.page") {
            self.initApp()
          } else if (_.get(data, "path", "") === "1.0.kiosk.ping") {
            self.lastKioskPong = Date.now()
            if (self.isWebOffline === true) {
              self.isWebOffline = false
            }
            self.dataInitialized = true
          }
        }
      }
      self.websocket_common_conn.onopen = function (e) {
        self.startCheckKioskConnectionHeath()
        self.initPingWebsocket()
        self.websocketReadyStatus = self.websocket_common_conn.OPEN
      }
      self.websocket_common_conn.onclose = function (e) {
        self.websocketReadyStatus = self.websocket_common_conn.CLOSED
      }
    },

    startCheckKioskConnectionHeath() {
      let self = this
      if (
        self.productionMode &&
        !_.isUndefined(self.kioskName) &&
        self.kioskName != "undefined"
      ) {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.kiosk.mark.connection",
            args: {
              kiosk_key: self.$route.params.link_hash,
            },
          })
        )
      }
    },

    getFirstPlaylist() {
      let self = this
      let firstPlaylist = null
      if (!_.isUndefined(self.rcEngineData["playlists"])) {
        firstPlaylist = self.rcEngineData["playlists"][self.playlistActiveIndex]
      }
      return firstPlaylist
    },

    getGroupsPlaylists() {
      let self = this
      let firstPlaylist = self.getFirstPlaylist()
      let groupsPlaylists = firstPlaylist ? firstPlaylist.groupsData : []
      return groupsPlaylists
    },

    goToGroup(key) {
      let self = this
      self.activeGroup = key
      self.initTimerRedirectToGroups()
    },

    backToGroups() {
      this.activeGroup = null
      this.clearTimerRedirectToGroups()
    },

    playSpecificItem(item) {
      let self = this
      self.resetLoadingFiles()
      if (!self.playingStarted) {
        self.playingStarted = true
        setTimeout(function () {
          self.playingStarted = false
        }, 2500)
        if (self.canChangePlayedItem) {
          self.playItem(item.pos)
          item.loading = true
        } else {
          // console.log('cannot ChangePlayedItem');
        }
      }
    },

    switchCanChangePlayedItem() {
      let self = this
      let firstPlaylist = self.getFirstPlaylist()
      if (firstPlaylist["groups"][firstPlaylist.index] == "") {
        self.canChangePlayedItem = true
      } else {
        self.canChangePlayedItem = false
      }
    },

    getNowPlayingFileName() {
      let self = this
      let firstPlaylist = self.getFirstPlaylist()
      let nowPlayingFile =
        _.isUndefined(firstPlaylist) || firstPlaylist.index == -1
          ? null
          : firstPlaylist["items"][firstPlaylist.index]

      let nowPlayingFileName =
        typeof nowPlayingFile === "object"
          ? nowPlayingFile?.name
          : nowPlayingFile
      if (nowPlayingFileName != null) {
        self.resetLoadingFiles()
      }
      return nowPlayingFileName
    },

    resetLoadingFiles() {
      let activeGroupInfo = this.getActiveGroupInfo()
      if (activeGroupInfo) {
        _.forEach(activeGroupInfo.items, function (value) {
          value.loading = false
        })
      }
    },

    // set timer for redirect to groups page if nothing happened on page with files after 3 minutes
    initTimerRedirectToGroups() {
      let self = this
      self.clearTimerRedirectToGroups()
      if (self.activeGroup != null && self.canChangePlayedItem == true) {
        self.objRedirectGroups = setTimeout(function () {
          self.backToGroups()
        }, 180000)
      }
    },

    // clear timer for redirect to groups page
    clearTimerRedirectToGroups() {
      let self = this
      clearTimeout(self.objRedirectGroups)
      self.objRedirectGroups = null
    },

    // ping every 15 minutes to websocket server
    initPingWebsocket() {
      let self = this
      setTimeout(function () {
        self.websocket_common_conn.send(
          JSON.stringify({
            type: "ping",
            message: "ping",
            CmdId: "1111111",
          })
        )
        self.initPingWebsocket()
      }, 600000)
    },

    // on start page log that current kiosk is opened in web page (only on Prod site)
    logGraylog(type) {
      let self = this
      try {
        let kioskEngineName = self.rcHashInfo?.kiosk?._engine?.name
        let kioskProjectName = self.rcHashInfo?.kiosk?._rc_engine?.project
        let title = `Kiosk web page for engine ${kioskEngineName}, project ${kioskProjectName}`
        if (type === "in_alive") {
          title = `${title} is alive on load page`
        }
        if (type === "switch_alive") {
          title = `${title} switched to alive`
        }
        if (type === "switch_maintenance") {
          title = `${title} switched to maintenance mode`
        }
        if (type === "in_maintenance") {
          title = `${title} is in maintenance mode on load page`
        }
        publicIp.v4().then((ip) => {
          let msg = {
            short_message: title,
            full_message: title,
            level: GraylogService.constructor.INFO_LEVEL,
            version: "1.1",
            facility: "arht.web",
            source: window.location.hostname,
            remote_ip: ip,
            user: JSON.stringify({
              email: self.rcHashInfo?.user?.email,
              first_name: self.rcHashInfo?.user?.first_name,
              last_name: self.rcHashInfo?.user?.last_name,
            }),
          }
          GraylogService.setGraylogInstance("dev")
          GraylogService.dump(["prod"], msg)
        })
      } catch (e) {
        console.log("Error on logGraylog", e)
      }
    },
  },

  computed: {
    ...mapGetters({
      rcEngineData: "getRcEngineData",
      websocket_common_conn: "getWebsocketCommonConnection",
    }),
    getRCkey() {
      return _.get(this.rcHashInfo, ["kiosk", "rc_engine_key"])
    },
    isMaintanceMode() {
      return (
        this.isWebOffline ||
        !this.rcEngineData.is_active ||
        !this.rcEngineData._engine.is_online
      )
    },
  },
}
</script>

<style>
.rc-text-select-hologram {
  line-height: 30px !important;
  height: 30px !important;
}

.rc-group-link {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #e8e8e8;
  text-decoration: none;
}

.rc-group-btn,
.rc-file-btn {
  font-size: 22px !important;
  text-transform: none;
  /*min-height: 88px;*/
  height: auto !important;
  text-align: center;
}

.rc-file-btn .v-btn__content {
  justify-content: center !important;
}

.rc-group-btn .v-btn__content,
.rc-file-btn .v-btn__content {
  white-space: pre-wrap;
  padding: 20px 0;
  width: 100%;
}

.rc-njhof-logo {
  position: absolute;
  background-image: url("../../../../../assets/img/rogers-kiosk/njhof-logo.png");
  width: 95px;
  height: 95px;
  background-size: 100%;
  top: 7px;
  right: 50px;
}

.rc-file-btn .v-btn__loading {
  background-color: #fbc02d !important;
  border-color: #fbc02d !important;
  color: #fff !important;
}

.rc-group-btn:hover:before,
.rc-file-btn:hover:before {
  opacity: 0 !important;
}
</style>
